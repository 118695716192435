import React, { useState, useEffect } from 'react';
import {
  Table,
  Pagination,
  Nav
} from 'react-bootstrap';
import axios from 'axios';
import '../../styles/App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTable, useSortBy, usePagination, useRowSelect } from 'react-table';
import { CustomCheckbox } from '../../components/CustomCheckbox/CustomCheckbox';
import SizingResultsModal from '../../components/CustomModals/SizingResultsModal';
import { connect } from 'react-redux';
import { massFlowAccuracy, compareSizingResults, compareDisableButton, productImageUrl, showMoreRatesLoader, getSizingData, productImageUrlLoad, productImageApiLoad, flowAccuracy, compareModelNames, showMoreRatesData, wasClicked, selectedRowData, configWarningMsg, defaultRow, densityAccuracy, sonicVelocityWarningMsg } from '../../redux/actions';
import { GET_PRODUCT_IMAGE_URL, PRODUCT_IMAGE, SHOW_MORE_RATES } from '../../constants/constants';
import CustomTranslation from '../../components/CustomTranslation/CustomTranslation';
import {DENSITY_VALUES, VISCOSITY_VALUES, VORTEX_VALUES, MAGNETIC_VALUES} from '../../constants/constants';
import { getCancelToken, resetCancelToken } from '../../UtilityFuntions/cancelTokenManager';

const SizingResultsTableStructure = ({ massFlowAccuracy, getSizingData, flowAccuracy, selectedRowData, showMoreRatesData, productImageUrl, productImageUrlLoad, productImageApiLoad, columns, data, tableType, headers, compareSizingResults, compareDisableButton, sizing_results_data, compareModelNames,results,props,count,checked, is_cryo ,defaultRow}) => {
  const NUM_COMPARE_ALLOWED = 3;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [currentlyChecked, setCurrentlyChecked] = useState(null);
  const [currentCheckboxes, setCurrentCheckboxes] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [clickedCell, setClickedCell] = useState(0);
  const [modelName, setModelName] = useState('');
  const [rowData,  setRowData]=useState('')
  const [prevSelectedRow, SetPrevSelectedRow] = useState('');
  const [modelImgNames, setModelImgNames] = useState([]);
  const [warningMessage, setwarningMessage] = useState(false);
  const languageCode = props.user_language && props.user_language.value
    ? props.user_language.value
    : 'US';
  const insertColumn = (arr, index, newItem) => [
    ...arr.slice(0, index),
    newItem,
    ...arr.slice(index)
  ];

  const productImageAPI = (name,desc) => {
    const data = {
      "data": [
        {
          "model_code": name,
          "language":  props.query_params.language?props.query_params.language:languageCode,
        }
      ]
    };
  
    const fetchData = async () => {
      const cancelToken = getCancelToken(PRODUCT_IMAGE);
      await axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}${GET_PRODUCT_IMAGE_URL}`,
        headers: {
          'Content-Type': 'application/json'
        },
        xsrfHeaderName: 'X-CSRFToken',
        xsrfCookieName: 'csrftoken',
        cancelToken: cancelToken,
        data: data
      })
        .then(function (response) {
          let data=response.data.data;
          data[0].description=desc;
          productImageUrl(data);
          productImageUrlLoad(false);
          productImageApiLoad(false);
          resetCancelToken(PRODUCT_IMAGE);
        })
        .catch(function (error) {
          if (axios.isCancel(error)) {
            console.log("cancel error",error.message);
          } else {
            console.error('error', error);
          }
        })
    }
        fetchData();
  }

 const showMoreRates= (result,accuracy) =>{ 
  const fetchData = async () => {
    let data={
      email_id:props.email,
      technology:props.search_parameters.select_technology.toLowerCase(),
      fluid_state:props.fluid_state.toUpperCase(),
      flow_accuracy: props.fluid_state.toLowerCase() !== 'gas'? parseFloat(accuracy)?parseFloat(accuracy):props.selected_row_data.length>0?props.selected_row_data.flow_accuracy_radio_button[0]:'':parseFloat(accuracy),
      volumetric_accuracy:parseFloat(accuracy),
      flow_rate_min: props.min_flow_rate!==""?parseFloat(props.min_flow_rate):'',
      flow_rate_norm: props.normal_flow_rate!==""?parseFloat(props.normal_flow_rate):'',
      flow_rate_max: props.max_flow_rate!==""?parseFloat(props.max_flow_rate):'',
      flow_rate_fsd: props.full_flow_rate!==""?parseFloat(props.full_flow_rate):'',
      calculate_density_variation: props.calculate_density_variation !== "" ? props.calculate_density_variation.toString() :'',
      line_pressure_min: props.min_line_pressure!==""?parseFloat(props.min_line_pressure):'',
      line_pressure_norm: props.normal_line_pressure!==""?parseFloat(props.normal_line_pressure):'',
      line_pressure_max: props.max_line_pressure!==""?parseFloat(props.max_line_pressure):'',
      line_pressure_fsd: props.full_line_pressure!==""?parseFloat(props.full_line_pressure):'',
      sonic_velocity: props.sonic_velocity !== ""? props.sonic_velocity : "",
      sonic_velocity_map: (Object.keys(props.sonic_velocity_map).length > 0 && props.sonic_velocity !== "") ? props.sonic_velocity_map : {},
      sonic_velocity_units: props.units_sonic_velocity.key !== ""? props.units_sonic_velocity.key : "",
      temperature_min: props.min_temperature!==""?parseFloat(props.min_temperature):'',
      temperature_norm: props.normal_temperature!==""?parseFloat(props.normal_temperature):'',
      temperature_max:props.max_temperature!==""?parseFloat(props.max_temperature):'',
      temperature_fsd: props.full_temperature!==""?parseFloat(props.full_temperature):'',
      mag_high_pressure : "true",
      density_min: props.min_density!==""?parseFloat(props.min_density):'',
      fluid_source: props.fluid_source?props.fluid_source.toUpperCase():'', 
      density_norm: props.fluid_state.toLowerCase() ==='gas' && props.fluid_source.toLowerCase()==='custom'  ?
      props.units_density_heading ==='Density' ?   props.normal_density !== "" && props.normal_density !== undefined ? parseFloat(props.normal_density) : "":
      props.density_specific_gravity !=="" && props.density_specific_gravity !== undefined?props.density_specific_gravity:'': 
      props.normal_density !== "" && props.normal_density !== undefined ? parseFloat(props.normal_density) : "",
  
      density_max: props.max_density!==""?parseFloat(props.max_density):'',
      density_units: props.fluid_state.toLowerCase() ==='gas' && props.fluid_source.toLowerCase()==='custom'  ?
      props.units_density_heading ==='Density' ?   props.units_density !== "" && props.units_density !== undefined ? props.units_density.key : "":
      props.units_gas_density_sg !=="" && props.units_gas_density_sg !== undefined?props.units_gas_density_sg.key:'': 
      props.units_density !== "" && props.units_density !== undefined ? props.units_density.key : "",
      
      viscosity_units: props.units_viscosity.key,
      is_chk_non_newton_liquid: props.non_newtonian_liquid,
      vapour_pressure: props.min_vapor,
      vapor_pressure_units: props.units_vapor.key,
      line_pressure_units: props.units_line_pressure.key,
      line_size: props.search_parameters.select_technology.toLowerCase()===VORTEX_VALUES || props.search_parameters.select_technology.toLowerCase()===MAGNETIC_VALUES?
      props.process_line_size:props.line_size.replace(/\s/g, ''),
      temperature_units: props.units_temperature.key,
      units_ambient: props.units_ambient.key,
      flow_rate_units: props.fluid_state.toLowerCase()==="gas"? props.units_gas_flow_rate.key: props.units_flow_rate.key,
      ref_density:props.gas_density,
      ref_density_units: props.units_gas_density !=='' && props.units_gas_density !==undefined ?props.units_gas_density.key:'',
      k_flow_consistency_text: props.k_flow_consistency_index !== ''? props.k_flow_consistency_index:'',
      n_flow_behavior_text: props.n_flow_behavior !== ''? props.n_flow_behavior:'',
      viscosity_min: props.min_viscosity !== ''?parseFloat(props.min_viscosity):'',  
      viscosity: props.normal_viscosity !== ''?parseFloat(props.normal_viscosity):'',
      viscosity_max: props.max_viscosity !==''? parseFloat(props.max_viscosity):'',
      sensor_list:[result],
      specific_gravity: props.specific_gravity !="" && props.specific_gravity != undefined ? parseFloat(props.specific_gravity):"",
    }
    const cancelToken = getCancelToken(SHOW_MORE_RATES);

    await axios({
    method: 'post',
    url: `${process.env.REACT_APP_BASE_URL}/mmipa/show_more_rates_calculation/`,
    headers: {
      'Content-Type': 'application/json'
    },
    xsrfHeaderName: 'X-CSRFToken',
    xsrfCookieName: 'csrftoken',
    cancelToken: cancelToken,
    data: data
    })
    .then(async function  (response) { 
      if(response.data.data){
        await showMoreRatesData(response.data.data)
        await props.wasClicked(false);
        resetCancelToken(SHOW_MORE_RATES);
      }  
    })
    .catch(async function (error) {
      await showMoreRatesData([])
      await props.wasClicked(false);
      if (axios.isCancel(error)) {
        console.log("cancel error",error.message);
      } else {
        console.error('error', error);
      }
    })
  }
    if(((props.search_parameters.select_technology.toLowerCase()=== DENSITY_VALUES||
    props.search_parameters.select_technology.toLowerCase()=== VISCOSITY_VALUES )&&
    props.fluid_state.toLowerCase()==='gas'&&
    (props.tank||props.in_line||props.flow_through_chamber_slipstream))){
     //No API call
    }else if(result?Object.keys(result).length>0:false){
      fetchData();
    }
  

 }

 useEffect(() => {

if(sizing_results_data.length > 0&&data.length>0){
      let  data = results ? results.filter((data)=>(props.search_parameters.select_technology.toLowerCase() === MAGNETIC_VALUES || props.search_parameters.select_technology.toLowerCase() === VORTEX_VALUES ? data.parent_model === props.selected_row_data.parent_model : data.product === props.selected_row_data.product)):''
      toggleAllRowsSelected(false);
      productImageUrlLoad(true);
      let desc=data[0].description ? data[0].description : data[0].short_description
      productImageAPI(props.search_parameters.select_technology.toLowerCase()==='magnetic'?data[0].parent_model:data[0].product,desc);
      showMoreRates(data[0],props.flow_accuracy.replace('%',''))
      selectedRowData(data[0]?data[0]:[])
    }
  }, [props.flow_accuracy]);

  useEffect(() => { 
    if(sizing_results_data.length > 0&&data.length>0){
          showMoreRatesData([])
          flowAccuracy('')
          let  data = results ? results.filter((data)=>(props.search_parameters.select_technology.toLowerCase() === MAGNETIC_VALUES || props.search_parameters.select_technology.toLowerCase() === VORTEX_VALUES ? data.parent_model === sizing_results_data[0].modelName : data.product === sizing_results_data[0].modelName)):''
          toggleAllRowsSelected(false);
          productImageUrlLoad(true);
          let desc=sizing_results_data[0].modelDescription?sizing_results_data[0].modelDescription:sizing_results_data[0].description
          productImageAPI(sizing_results_data[0].modelName,desc);
          showMoreRates(data[0],'')
          selectedRowData(data!==''?data[0]:[])
          defaultRow(true)
       //loader should be displayed only when showMoreRatesLoader is false
    } else if (sizing_results_data.length > 0 && data.length === 0){       
      showMoreRatesLoader(true);
    } else{
      showMoreRatesLoader(false);
      //Discart changes
      props.wasClicked(false);
    }
    }, [sizing_results_data]);

  useEffect(() => {
      compareModelNames(modelImgNames);
  }, [modelImgNames]);

  const cellClick = (row, cell, toggleAllRowsSelected, selectedRowIds) => {
    SetPrevSelectedRow(props.selected_row_data)
    showMoreRatesData([])
    selectedRowData({})
    massFlowAccuracy({})

    flowAccuracy('')
    if(props.search_parameters.select_technology.toLowerCase()=== DENSITY_VALUES &&(props.fluid_state.toLowerCase()==="liquid"||props.fluid_state.toLowerCase()==="slurry")){
      let inch =eval(props.line_size.substr(0,props.line_size.indexOf('inch')).split(" ").filter(element => {
        return element !== "";
      }).join('+'))
      if(inch>2&&row.values.modelName.includes("CDM")){
        setwarningMessage(true)
      }else{
        setwarningMessage(false)
      }
    }
    let model_name=row.values.modelName.replace(/\*/g, "");
    let  data=results ?results.filter((data)=>(props.search_parameters.select_technology.toLowerCase() === MAGNETIC_VALUES || props.search_parameters.select_technology.toLowerCase() === VORTEX_VALUES  ? data.parent_model=== row.values.modelName:data.product=== model_name)):'';
    if (!selectedRowIds[row.id]) {
      if(row.index > 0){
        defaultRow(false)
      }
      setClickedCell(row.index);
      productImageUrlLoad(true);
      productImageApiLoad(true);
      toggleAllRowsSelected(false);
      setModelName(row.values.modelName);
      productImageAPI(row.values.modelName,row.values.modelDescription?row.values.modelDescription:row.values.description);
      showMoreRates(data[0],'')
      props.densityAccuracy('')
      selectedRowData(data[0]?data[0]:[])
    }
    if (cell?cell.column.id === 'compare':false) {
      checkboxChecked(row.id, row.values.modelName)
    }
  };

  // fired off when a previous checkbox is checked
  useEffect(() => {
    if (isChecked) {
      setCurrentCheckboxes([...currentCheckboxes, currentlyChecked]);
    } else {
      setCurrentCheckboxes(currentCheckboxes.filter((e) => (e !== currentlyChecked)));
    }
  }, [isChecked]);
  useEffect(() => {
    compareSizingResults([]);
      setCurrentCheckboxes([]);
      compareModelNames([])
      setModelImgNames([])
      let checkbox=[0,1,2,3,4,5,6,7,8,9,10]
      checkbox.map(c=>{
        const checkbox = document.querySelector(`input[type='checkbox'][value='${c}']`);
        let data=checkbox?checkbox.checked= false:false;
      })
  }, [checked]);
  //fired off when a new checkbox is checked
  useEffect(() => {
    if (isChecked) {
      setCurrentCheckboxes([...currentCheckboxes, currentlyChecked]);
    } else {
      setCurrentCheckboxes(currentCheckboxes.filter((e) => (e !== currentlyChecked)));
    }
  }, [currentlyChecked]);

  useEffect(() => {
    if (currentCheckboxes.length > NUM_COMPARE_ALLOWED) {
      // setCurrentCheckboxes(currentCheckboxes.filter((e) => (e !== currentlyChecked)))
      // const checkbox = document.querySelector(`input[type='checkbox'][value='${currentlyChecked}']`);
      // checkbox.checked = !checkbox.checked;
      setIsChecked(false);
      setCurrentlyChecked(null);
      setShow(true);
      compareDisableButton(true); 
    }
    if (currentCheckboxes.length > 0 && currentCheckboxes.length <= NUM_COMPARE_ALLOWED) {
      const rows = currentCheckboxes.map(box => sizing_results_data[box])
      compareSizingResults(rows !== null ? rows : []);
      compareDisableButton(false);
    }
  }, [currentCheckboxes]);

  const checkboxChecked = (rowId, rowNames) => {
    const value = rowId;
    const name = rowNames;
    if(name !==undefined){
      const checkbox = document.querySelector(`input[type='checkbox'][value='${value}']`);
      checkbox.checked = !checkbox.checked
      //verify if it's clicked a new value
      if (checkbox.checked === true) {
        //check if the element exist in the models list and enter it if not
        if(currentlyChecked === null ? true: (props.compare_model_names.includes(name) === true? false:true ) ){
          if(name !==undefined){
            setCurrentlyChecked(value);
            setIsChecked(true);
            setModelImgNames(prev => [...prev, name]);
          }
        }
      } else {
        if(name !== undefined){
          setModelImgNames(modelImgNames.filter((e) => (e !== name)));
          setCurrentlyChecked(value);
          setIsChecked(false);
        }
      }
    }
    
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    toggleAllRowsSelected,
    state: { pageIndex, selectedRowIds },
  } = useTable(
    {
      columns, data,initialState: { pageIndex: 0 },
    },
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => insertColumn(columns, 1, ...[
        {
          id: 'compare',
          Header: 'COMPARE',
          Cell: ({ row }) => (
            <div>
                <CustomCheckbox
                  name="compare-checkbox"
                  value={row.id}
                  label=""
                  onChange={() => checkboxChecked(row.id)}
                  disabled={false}
                />
            </div>
          ),
        }], ...columns))
    }
  );
  const [page_data, setPageData] = useState([page]);
  useEffect(() => {
    if(data.length>0){
      let result=page.length>0?page.map(data=>{
        if(data.values.modelName!==undefined){
        if(data.values.modelName.startsWith("K")&&!data.values.modelName.includes('*')){
          data.values.modelName=data.values.modelName+'*'
        }
        if(data.values.modelName.endsWith("G")&&!data.values.modelName.includes('*')){
          data.values.modelName=data.values.modelName+'*'
        }

      }
      }):[]
      setRowData(page[0])
      setClickedCell(page.length>0?page[0].index:'')
       let res=page.length>0?
      cellClick(page[0],null,toggleAllRowsSelected, selectedRowIds):''
      currentCheckboxes.map(c=>{
        const checkbox = document.querySelector(`input[type='checkbox'][value='${c}']`);
        let data=checkbox?checkbox.checked= true:'';
      })
    }
  
  }, [page,active]);

  useEffect(() => {
    currentCheckboxes.map(c=>{
      const checkbox = document.querySelector(`input[type='checkbox'][value='${c}']`);
      let data=checkbox?checkbox.checked= true:false;
    })
  }, [page_data,currentCheckboxes]);

  useEffect(() => {
  let page_dat  =pageIndex===0&&(props.search_parameters.select_technology.toLowerCase()==='coriolis'||props.search_parameters.select_technology.toLowerCase()==='density')?page.filter((row, i) => 
    i<count):page
    let filtered=page_dat
  if (Object.keys(props.mass_flow).length>0&&props.show_more_rates_result_data.length>0&&props.search_parameters.select_technology.toLowerCase()==='coriolis') {
    
   
    if(props.fluid_state.toLowerCase()!=='gas'){
     
      try {
        if(prevSelectedRow!=''){
          filtered=page_dat? page_dat.filter(data=>
            data.values.modelName.replace(/\*/g,"")===prevSelectedRow.product?
             prevSelectedRow.flow_accuracy_radio_button.length>0 ?
            (data.values.massNormal=
              prevSelectedRow.flow_accuracy_radio_button.includes(prevSelectedRow.dmf_accuracy[1])&&prevSelectedRow.dmf_accuracy[1]!==null?
              prevSelectedRow.dmf_accuracy[1]:
              prevSelectedRow.dmf_accuracy[1]!==null? 
              prevSelectedRow.dmf_accuracy[1].toFixed(props.all_other_decimal):'--', 
            data.values.massMin= 
              prevSelectedRow.flow_accuracy_radio_button.includes(prevSelectedRow.dmf_accuracy[0])&&prevSelectedRow.dmf_accuracy[0]!==null?
              prevSelectedRow.dmf_accuracy[0]:
              prevSelectedRow.dmf_accuracy[0]!==null?
              prevSelectedRow.dmf_accuracy[0].toFixed(props.all_other_decimal):'--',
            data.values.massMax=
              prevSelectedRow.flow_accuracy_radio_button.includes(prevSelectedRow.dmf_accuracy[2])&&prevSelectedRow.dmf_accuracy[2]!==null?
              prevSelectedRow.dmf_accuracy[2]:
              prevSelectedRow.dmf_accuracy[2]!=null?
              prevSelectedRow.dmf_accuracy[2].toFixed(props.all_other_decimal):'--')
             :(
            data.values.massNormal=prevSelectedRow.dmf_accuracy[1]!=='--'?
             prevSelectedRow.dmf_accuracy[1].toFixed(props.all_other_decimal):'--', 
            data.values.massMin=parseFloat(prevSelectedRow.dmf_accuracy[0])!='--'?
             prevSelectedRow.dmf_accuracy[0].toFixed(props.all_other_decimal):'--',
            data.values.massMax=prevSelectedRow.dmf_accuracy[2]!='--'?
             prevSelectedRow.dmf_accuracy[2].toFixed(props.all_other_decimal):'--')
             :data):'--'
         }
      } catch (error) {
        //no actions
      }
      try {
        if(prevSelectedRow!=''){
          filtered=page_dat? page_dat.filter(data=>
            data.values.modelName.replace(/\*/g,"")===prevSelectedRow.product?
             prevSelectedRow.density_accuracy_radio_button.length>0 ?
            (data.values.densityAccuracy=
              prevSelectedRow.density_accuracy_radio_button.includes(prevSelectedRow.density_accuracy)&&prevSelectedRow.density_accuracy!==null?
              prevSelectedRow.density_accuracy.toFixed(props.all_other_decimal):'--')
              :(
                data.values.densityAccuracy=prevSelectedRow.density_accuracy!=='--' && is_cryo ? 
                 prevSelectedRow.density_accuracy.toFixed(props.all_other_decimal):'--')
                 :data):'--'
              }
            }
              catch (error){
               //no actions
              }
      
      try {
      filtered=page_dat.filter(data=>
          data.values.modelName.replace(/\*/g,"")===props.selected_row_data.product &&  props.show_more_rates_data[0].product === props.selected_row_data.product?
          props.selected_row_data.flow_accuracy_radio_button?(
          data.values.massNormal= props.selected_row_data.flow_accuracy_radio_button.length !== undefined?
           props.selected_row_data.flow_accuracy_radio_button.includes(parseFloat(props.mass_flow.normal))?
           parseFloat(props.mass_flow.normal):parseFloat(props.mass_flow.normal).toFixed(props.all_other_decimal)
           :parseFloat(props.mass_flow.normal).toFixed(props.all_other_decimal), 
          data.values.massMin= props.selected_row_data.flow_accuracy_radio_button.length !== undefined?
           props.selected_row_data.flow_accuracy_radio_button.includes(parseFloat(props.mass_flow.min))?
           parseFloat(props.mass_flow.min):parseFloat(props.mass_flow.min)?
           parseFloat(props.mass_flow.min).toFixed(props.all_other_decimal)
           :props.mass_flow.min:parseFloat(props.mass_flow.min).toFixed(props.all_other_decimal) ,
          data.values.massMax=props.selected_row_data.flow_accuracy_radio_button.length !== undefined? 
          props.selected_row_data.flow_accuracy_radio_button.includes(parseFloat(props.mass_flow.max))?
          parseFloat(props.mass_flow.max):parseFloat(props.mass_flow.max)?
          parseFloat(props.mass_flow.max).toFixed(props.all_other_decimal):props.mass_flow.max :parseFloat(props.mass_flow.max).toFixed(props.all_other_decimal))
          :(data.values.massNormal=parseFloat(props.mass_flow.normal)?parseFloat(props.mass_flow.normal):'--', 
            data.values.massMin= parseFloat(props.mass_flow.min)?parseFloat(props.mass_flow.min):'--' ,
            data.values.massMax=parseFloat(props.mass_flow.max)?parseFloat(props.mass_flow.max):'--')
            :data)  
      } catch (error) {
        //no actions 
      } 

      try {
        filtered=page_dat.filter(data=>
          data.values.modelName.replace(/\*/g,"")===props.selected_row_data.product &&  props.show_more_rates_data[0].product === props.selected_row_data.product?
          props.selected_row_data.density_accuracy_radio_button && props.density_accuracy !== undefined && props.density_accuracy !== '' ?(
          data.values.densityAccuracy= props.selected_row_data.density_accuracy_radio_button.length !== undefined?
          props.selected_row_data.density_accuracy_radio_button.includes(props.density_accuracy)?
          parseFloat(props.density_accuracy):parseFloat(props.density_accuracy).toFixed(props.all_other_decimal)
          :parseFloat(props.density_accuracy).toFixed(props.all_other_decimal))
          :(data.values.densityAccuracy = props.density_accuracy !== undefined && props.density_accuracy !== ''?parseFloat(props.density_accuracy):'--')
          :data)
           
            }
            catch (error){
               //no actions
            }
    }
  }

  if(props.search_parameters.select_technology.toLowerCase()==='density') {
    // if(props.fluid_state.toLowerCase()!=='gas'){
    try {
      if (prevSelectedRow != '' && prevSelectedRow.product !== "SGM") {
        filtered = page_dat ? page_dat.filter(data =>
          data.values.modelName.replace(/\*/g, "") === prevSelectedRow.product ?
            prevSelectedRow.density_accuracy_radio_button.length > 0 ?
              (data.values.densityAccuracy =
                prevSelectedRow.density_accuracy_radio_button.includes(prevSelectedRow.density_accuracy) && prevSelectedRow.density_accuracy !== null ?
                  prevSelectedRow.density_accuracy.toFixed(props.all_other_decimal) : '--')
              : (
                data.values.densityAccuracy = prevSelectedRow.density_accuracy !== '--' ?
                  prevSelectedRow.density_accuracy.toFixed(props.all_other_decimal) : '--')
            : data) : '--'
      }
    }
    catch (error) {
      //no actions
    }
    try {
            filtered = page_dat.filter(data =>
        data.values.modelName.replace(/\*/g, "") === props.selected_row_data.product && props.show_more_rates_data[0].product === props.selected_row_data.product ?
          props.selected_row_data.density_accuracy_radio_button ? (
            data.values.densityAccuracy = props.selected_row_data.density_accuracy_radio_button.length !== undefined ?
              props.selected_row_data.density_accuracy_radio_button.includes(parseFloat(props.density_accuracy)) ?
                parseFloat(props.density_accuracy).toFixed(props.all_other_decimal) : parseFloat(props.density_accuracy).toFixed(props.all_other_decimal)


              : parseFloat(props.density_accuracy).toFixed(props.all_other_decimal))
            : (data.values.densityAccuracy = parseFloat(props.density_accuracy) ? parseFloat(props.density_accuracy).toFixed(props.all_other_decimal) : '--')
          : data)

    }
    catch (error) {
      //no actions
    }
                // }
            }


  setPageData(filtered)
  }, [rowData,page,pageIndex,prevSelectedRow,
      props.selected_row_data,props.mass_flow,selectedRowIds,props.density_accuracy]);
  
  //function to return max number of and object of values
    const returnMaxValue =(obj) => {
      let max_value = 0
      if(obj !==''){
        let set_values = obj !== undefined && obj !== '' ? Object.values(obj) : 0 
        let filter_values =  set_values.filter(value => value !== null) 
        max_value = Math.max(...filter_values)
        
      }
      return max_value
      
    }

    const returnMinValue =(obj) => {
      let min_value = 0
      if(obj !==''){
        let set_values = obj !== undefined && obj !== '' ? Object.values(obj) : 0 
        let filter_values =  set_values.filter(value => value !== null) 
        min_value = Math.min(...filter_values)
        
      }
      return min_value
      
    }

    const convertToCelsius =(temp, unit)=>{
      switch (unit) {
        case 'F':
          return (temp - 32) * (5 / 9);
        case 'K':
          return temp - 273.15;
        case 'C':
          return temp;
        case 'R':
          return (temp - 491.67) * (5 / 9);
        default:
          return NaN; // Invalid unit
      }
    }

    const convertToFahrenheit = (temp, unit)=>{
      switch (unit) {
        case 'F':
          return temp;
        case 'K':
          return (temp - 273.15) * (9 / 5) + 32;
        case 'C':
          return (temp * 9 / 5) + 32; 
        case 'R':
          return temp - 459.67; 
        default:
          return NaN; 
      }
    }

    //return the result of the validation to display or discard a message 
    const warningLimitMessage = ()=>{
      let warning_result = false
      let line_press_limit = false
      let temp_limit = false
      let highpressuremsg = props.selected_row_data.highpressuremsg !== undefined? 
      props.selected_row_data.highpressuremsg !==''?true:false : false 
      let temp_row_values = {
        0:props.min_temperature!==""?parseFloat(props.min_temperature):null,
        1:props.normal_temperature!==""?parseFloat(props.normal_temperature):null,
        2:props.max_temperature!==""?parseFloat(props.max_temperature):null,
        3:props.full_temperature!==""?parseFloat(props.full_temperature):null
      }

      let max_press_value = returnMaxValue(props.line_press_row)
      let max_temp_value = returnMaxValue(temp_row_values)
      let min_temp_value  = returnMinValue(temp_row_values)
      
      let max_temp_celcius = convertToCelsius(max_temp_value, props.units_temperature.key)
      let min_temp_celcius = convertToCelsius(min_temp_value, props.units_temperature.key)
      
      if(props.search_parameters.select_technology.toLowerCase()==='magnetic'|| props.search_parameters.select_technology.toLowerCase()==='vortex' && 
      (props.selected_row_data !== undefined && props.selected_row_data !==null)){
        warning_result =  props.selected_row_data.is_excluded 
      }else if( props.display_all_sensors_no_filters ){
        if(props.search_parameters.select_technology.toLowerCase()==='coriolis'||
         props.search_parameters.select_technology.toLowerCase()==='density'|| props.search_parameters.select_technology.toLowerCase()==='viscosity' ){
          line_press_limit = max_press_value >  props.selected_row_data.tubepressurerating
          temp_limit = max_temp_celcius > props.selected_row_data.maxtemperaturerating || min_temp_celcius < props.selected_row_data.mintemperaturerating
          if(line_press_limit || temp_limit || highpressuremsg){
            warning_result = true
          }else{
            warning_result = false
          }
        }
        
      }
      props.configWarningMsg(warning_result)
      return warning_result;

    }

    const highTemperatureWarningMessage = ()=>{

      let warning_result = false
      let temp_row_values = {
        0:props.min_temperature!==""?parseFloat(props.min_temperature):null,
        1:props.normal_temperature!==""?parseFloat(props.normal_temperature):null,
        2:props.max_temperature!==""?parseFloat(props.max_temperature):null,
        3:props.full_temperature!==""?parseFloat(props.full_temperature):null
      }

      let amb_temp_row_values = {
        0:props.min_ambient!==""?parseFloat(props.min_ambient):null,
        1:props.normal_ambient!==""?parseFloat(props.normal_ambient):null,
        2:props.max_ambient!==""?parseFloat(props.max_ambient):null,
        3:props.full_ambient!==""?parseFloat(props.full_temperature):null
      }

      const maxTemperature = returnMaxValue(temp_row_values)
      const maxAmbTemp = returnMaxValue(amb_temp_row_values)
      const firenheitTemperature = convertToFahrenheit(maxTemperature, props.units_temperature.key)
      const firenheitAmbTemperature = convertToFahrenheit(maxAmbTemp, props.units_ambient.key)

      if(props.search_parameters.select_technology.toLowerCase()==='coriolis'){
        if(firenheitTemperature >  350 || firenheitAmbTemperature > 350){
          if(props.selected_row_data.hightemp ===0){
            warning_result =  true
          }
        }else {
          warning_result  = false
        }
      }
     
      props.highTempWarningMsg(warning_result)
      return warning_result

    }

    const highTemperatureError = ()=>{

      let isHighTemperatureMeter = false
      const meter = props.selected_row_data.product  

      const regex = /^(CMF|F).*[AB]$/

      if(regex.test(meter)){
        isHighTemperatureMeter = true
      }
    
      props.highTemperatureErrorMsg(isHighTemperatureMeter)
      return isHighTemperatureMeter
    }

  let items = [];
  //pageIndex=6
  let active = pageIndex + 1;

    // Use the Web Crypto API for secure random values
  const secureRandomInt = () => {
    const array = new Uint16Array(1);
    window.crypto.getRandomValues(array);
    return array[0] % 10000;
  };
 
  // these are to generate random keys for the ellipses
  let ellipsis1 = Math.floor(secureRandomInt() + pageCount);
  let ellipsis2 = Math.floor(secureRandomInt() + pageCount);


  items.push(<Pagination.Prev onClick={() => previousPage()} key={-1} />)
  if (pageCount > 6 && active < 5) {
    for (let number = 1; number <= 5; number++) {
      items.push(
        <Pagination.Item key={number} active={number === active} onClick={() => gotoPage(number - 1)}>
          {number}
        </Pagination.Item>
      );
    }
    items.push(<Pagination.Ellipsis key={ellipsis1} />);
    items.push(
      <Pagination.Item key={pageCount} active={pageCount === active} onClick={() => gotoPage(pageCount - 1)}>
        {pageCount}
      </Pagination.Item>
    );

  } else if (pageCount >= 10 && active >= 5 && active < pageCount - 3) {
    items.push(
      <Pagination.Item key={1} active={1 === active} onClick={() => gotoPage(0)}>
        {1}
      </Pagination.Item>
    );
    items.push(<Pagination.Ellipsis key={ellipsis1} />);
    // block end is 2 from active, and block start is 2 before
    let blockEnd = active <= pageCount - 3 ? active + 2 : pageCount
    for (let number = active - 2; number <= blockEnd; number++) {
      items.push(
        <Pagination.Item key={number} active={number === active} onClick={() => gotoPage(number - 1)}>
          {number}
        </Pagination.Item>
      );
    }
    items.push(<Pagination.Ellipsis key={ellipsis2} />);
    items.push(
      <Pagination.Item key={pageCount} active={pageCount === active} onClick={() => gotoPage(pageCount - 1)}>
        {pageCount}
      </Pagination.Item>
    );

  } else if ((pageCount > 6 && pageCount < 10 && active >= 5) || (pageCount >= 10 && active > pageCount - 5)) {
    items.push(
      <Pagination.Item key={1} active={1 === active} onClick={() => gotoPage(0)}>
        {1}
      </Pagination.Item>
    );
    items.push(<Pagination.Ellipsis key={ellipsis2} />);
    for (let number = pageCount - 4; number <= pageCount; number++) {
      items.push(
        <Pagination.Item key={number} active={number === active} onClick={() => gotoPage(number - 1)}>
          {number}
        </Pagination.Item>
      );
    }

  } else {
    for (let number = 1; number <= pageCount; number++) {
      items.push(
        <Pagination.Item key={number} active={number === active} onClick={() => gotoPage(number - 1)}>
          {number}
        </Pagination.Item>
      );
    }
  }
  items.push(<Pagination.Next onClick={() => nextPage()} key={pageCount + 1} />)

  const tableClasses = `sizing-results-table ${tableType}`;
  return (
    <>
      <SizingResultsModal show={show} handleClose={handleClose} handleShow={handleShow} numberAllowed={NUM_COMPARE_ALLOWED} />
      <Table bordered hover responsive className={tableClasses} {...getTableProps()} >
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <span className="header-content">
                    <span className="span-center">
                      <TableHeader heading={(typeof column.Header) === 'string' ? column.Header : undefined} headers={headers} />
                    </span>
                  </span>
                  <span className="sort-arrow">
                    {
                      column.Header !== 'COMPARE'
                        && column.Header !== 'MASS FLOW RATE ACCURACY'
                        && column.Header !== 'PRESSURE DROP'
                        && column.Header !== 'TUBE VELOCITY'
                        && column.Header !== 'METER LIMITS'
                        && column.Header !== 'METER LIMITS (GALLONS(US)/MIN)'
                        && column.Header !== 'RECOMMENDED FLUID SERVICE LIMITS'
                        && typeof column.Header === 'string'
                        ? column.isSorted
                          ? column.isSortedDesc
                            ? <span className="icon-down" />
                            : <span className="icon-up" />
                          : <span className="icon-up-down" />
                        : <span />
                    }
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page_data.map((row, i) => {
           
            prepareRow(row)
            return (
              <tr {...row.getRowProps()} className={row.index === clickedCell ? 'selected-row' : ''} >
                {row.cells.map(cell => {
                  return cell.column.id === 'modelDescription' || cell.column.id === 'description'
                    ? <td {...cell.getCellProps()} onClick={() => cellClick(row, cell, toggleAllRowsSelected, selectedRowIds)}><div className="description-width">{cell.render('Cell')}</div></td>
                    : <td {...cell.getCellProps()} onClick={() => cellClick(row, cell, toggleAllRowsSelected, selectedRowIds)}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
            
          })}
        </tbody>
      </Table>
      <Nav className="justify-content-center">
        <span className="pagination-section"><Pagination>{items}</Pagination></span>
      </Nav>
      <br />
      <p style={{color:"red",alignContent: 'center',
      fontSize: '14px'}}>{warningMessage?<CustomTranslation data='XXSPECIFY_MMI_PR_CDM' />:
      props.selected_row_data?props.selected_row_data.cavitation_warning_msg?
      props.selected_row_data.cavitation_warning_msg:'':''} </p>
      { props.selected_row_data? props.selected_row_data.product?props.selected_row_data.product.startsWith("K")?
      <p> <CustomTranslation data="XXSPECIFY_MMI_SR_LBL_FEWCONT"/></p>:'':'':''}
      { props.selected_row_data? props.selected_row_data.product?props.selected_row_data.product.endsWith("G")?
      <p> <CustomTranslation data="XXSPECIFY_MMI_SR_LBL_FEWCONT"/></p>:'':'':''}
      { props.selected_row_data? props.selected_row_data.sonic_velocity_warning_msg !== "" ?
      <p style={{color:"red"}}>{props.selected_row_data.sonic_velocity_warning_msg}</p>:'':''}
      { props.selected_row_data? props.selected_row_data.amb_temp_warning_msg !== "" ?
      <p style={{color:"red"}}>{props.selected_row_data.amb_temp_warning_msg}</p>:'':''}
      { props.search_parameters.select_technology.toLowerCase()==='vortex'||props.search_parameters.select_technology.toLowerCase()==='magnetic'?
      <p style={{color:"blue",alignContent: 'center',
      fontSize: '14px'}}> <CustomTranslation data="XXSPECIFY_MMI_MAGVTXAMBTMP_LBL"/></p>:''}
        {
           warningLimitMessage()?
           <p style={{color:"red",alignContent: 'center',
           fontSize: '14px'}} >
            <CustomTranslation data="XXSPECIFY_MMI_MC_LBL_ERRMSG112"/>
           </p>
           :''
        }
        {
          highTemperatureWarningMessage() ?
          <p style={{color:"red",alignContent: 'center',
           fontSize: '14px'}} >
            <CustomTranslation data="XXSPECIFY_MMI_LBL_F_SERIES"/>
           </p>
           :''
        }
        {
          props.selected_row_data ?props.selected_row_data.press_drop_warning_msg !==''? 
          <p style={{color:"red"}}>{props.selected_row_data.press_drop_warning_msg}</p>:'':''
        }
        {
          highTemperatureError() ? 
          <p style={{color:"red",alignContent: 'center',
           fontSize: '14px'}} >
            <CustomTranslation data="XXSPECIFY_MMI_LBL_HI_TEMP_MDL"/>
           </p>
           :''
        }
    </>
  );
}

const TableHeader = ({ heading, headers}) => {
  const formatHeading = heading => {
    switch (heading) {
      case 'MODEL NAME':
        return <> <CustomTranslation data='XXSPECIFY_MMI_SR_TH1_MODNAM' /> </>
      case 'COMPARE':
        return <CustomTranslation data='XXSPECIFY_MMI_SR_LBL_COMPARE' />
      case 'PRESSURE DROP':
        return <><CustomTranslation data='XXSPECIFY_MMI_SR_TH1_PRDRP' /> <span className="sub-text-uom">{`(${headers.pressure_drop})`}</span></>;
      case 'TUBE VELOCITY':
        return <><CustomTranslation data='XXSPECIFY_MMI_SR_TH1_TUBVEL' /> <span className="sub-text-uom">{`(${headers.tube_velocity})`}</span></>;
      case 'DENSITY ACCURACY':
        return <><CustomTranslation data='XXSPECIFY_MMI_SR_TBL_DENSACC' /><br /><span className="sub-text-uom">{`(${headers.density_accuracy})`}</span></>;
      case 'MODEL DESCRIPTION':
        return <><CustomTranslation data='XXSPECIFY_MMI_SR_TH1_DESC' /></>;
      case 'SUGGESTED METER':
        return <><CustomTranslation data='XXSPECIFY_MMI_SR_TBL_SUGG' /></>;
      case 'MIN MEASURABLE':
        return <><CustomTranslation data='XXSPECIFY_MMI_SR_TH1_MIN_MSR' /></>;
      case 'MAX MEASURABLE':
        return <><CustomTranslation data='XXSPECIFY_MMI_SR_TH1_MAX_MSR' /></>;
      case 'MIN ACCURATE':
        return <><CustomTranslation data='XXSPECIFY_MMI_SR_TH1_MIN_ACR' /></>;
      case 'MAX ACCURATE':
        return <><CustomTranslation data='XXSPECIFY_MMI_SR_TH1_MAX_ACR' /></>;
      case 'LINE SIZE':
        return <><CustomTranslation data='XXSPECIFY_MMI_SR_TBL_LINESIZE' /><br /><span className="sub-text-uom">{`(${headers.line_size})`}</span></>
      case 'MINIMUM ANALOG SPAN (4-20MA)':
        return <><CustomTranslation data='XXSPECIFY_MMI_SR_TH1_MIN_ANL' /></>;
      case 'MINIMUM UPSTREAM PRESSURE':
        return <><CustomTranslation data='XXSPECIFY_MMI_SR_TH1_UP_STRM' /> <span className="sub-text-uom">{`(${headers.minimum_upstream_pressure})`}</span></>;
      case 'METER MIN ACC FLOW @ 1.0%':
        return <><CustomTranslation data='XXSPECIFY_MMI_SR_TBL_MTRMINACC' /> 1.0%</>;
      case 'ESTIMATED LOW FLOW CUTOFF':
        return <><CustomTranslation data='XXSPECIFY_MMI_SR_TBL_LOWCUTOFF' /><br/><span className="sub-text-uom">{`(${headers.Estimated_low_flow_cutoff})`}</span></>
      case 'RECOMMENDED FLUID SERVICE LIMITS':
        return <><CustomTranslation data='XXSPECIFY_MMI_SR_TH1_REC_FLU' /><br/><span className="sub-text-uom">{`(${headers.recommended_fluid_service_limits})`}</span></>;
      case 'METER LIMITS':
        return <><CustomTranslation data='XXSPECIFY_MMI_SR_TH1_MTR_LIM' /> <span className="sub-text-uom">{`(${headers.recommended_fluid_service_limits})`}</span></>;
      case 'MASS FLOW RATE ACCURACY':
        return <><CustomTranslation data='XXSPECIFY_MMI_SR_MA_FLO_ACC' /></>;
      default:
        return heading;
    }
  }

  return (
    <>
      { formatHeading(heading)}
    </>
  )
}

const mapStateToProps = state => ({
  fluid_source: state.FluidSelection.fluid_source,
  fluid_state: state.FluidSelection.fluid_state,
  compare_sizing_results: state.SizingResults.compare_sizing_results,
  compare_disable_button: state.SizingResults.compare_disable_button,
  sizing_results_data: state.SizingResults.sizing_results_data, 
  sonic_velocity: state.ProcessVariables.sonic_velocity,
  sonic_velocity_map: state.ProcessVariables.sonic_velocity_map,
  units_sonic_velocity: state.ProcessVariables.units_sonic_velocity, 
  search_parameters: state.SizingResults.search_parameters,
  query_params: state.Preferences.query_params,
  user_language: state.AppHeader.user_language,
  selected_row_data: state.SizingResults.selected_row_data,
  amb_temp_warning_msg: state.SizingResults.amb_temp_warning_msg,
  display_all_sensors_no_filters:
  state.ApplicationRequirements.display_all_sensors_no_filters,
  min_vapor: state.ProcessVariables.min_vapor,
  units_vapor: state.ProcessVariables.units_vapor,
  translation_data: state.TranslationData.translation_data,
  non_newtonian_liquid : state.ProcessVariables.non_newtonian_liquid,
  k_flow_consistency_index: state.ProcessVariables.k_flow_consistency_index,
  n_flow_behavior: state.ProcessVariables.n_flow_behavior,
  units_gas_density_sg: state.ProcessVariables.units_gas_density_sg,
  density_specific_gravity: state.ProcessVariables.density_sg,
  line_size: state.ApplicationRequirements.line_size,
  process_line_size:state.ApplicationRequirements.process_line_size,
  specific_gravity: state.ProcessVariables.specific_gravity,
  email:state.AppHeader.email,
  
 
});

export default connect(
  mapStateToProps,
  {
    compareSizingResults,
    compareDisableButton,
    productImageUrl,
    productImageUrlLoad,
    productImageApiLoad,
    showMoreRatesLoader,
    compareModelNames,
    showMoreRatesData,
    selectedRowData,
    getSizingData,
    flowAccuracy,
    massFlowAccuracy,
    configWarningMsg,
    sonicVelocityWarningMsg,
    defaultRow, 
    densityAccuracy
  }
)(SizingResultsTableStructure);
